/*eslint-disable */

import ApiResponse from './apiResponse'
import { User } from '../FackApi/api/user.js'

let AutoLogin = {
  /**
   * auto_login
   * Auto User the User
   */
  async autoLogin(context, userObj, login_type) {
    let api = "user_login_email";

    if(login_type == 'email') {
      api = 'user_login_email';
    }
    else if(login_type == 'mobile'){
      api = 'user_login_mobile';
    }
    else if(login_type == 'google'){
      api = 'user_login_google';
      if(!userObj.user_email){
        return false;
      }    
    }
    else if(login_type == 'apple'){
      api = 'user_login_apple';
      if(!userObj.code || !userObj.user_email){
        return false;
      }
    }
    else{
      return false;
    }

    //Keep me logged in and preference_cookie are the same so if preference_cookie is set to true than
    //keep_me_logged_in will also be true and visa versa
    // if(context.cookieAcceptance && context.cookieAcceptance.preference_cookie) {
    //   post_data.append("keep_me_logged_in", true);
    // }

    let userLoginResp = await User.userLogin(context, userObj, api)
    await ApiResponse.responseMessageDisplay(context, userLoginResp)
    await this.setUserLoginProfile(context, userLoginResp, login_type);

  },
   /**
   * setUserLoginProfile
   * Setup profile the User
   */
  async setUserLoginProfile(context, userLoginResp, login_type=null){
    if (userLoginResp.resp_data && userLoginResp.resp_data) {
      let userProfileData = userLoginResp.resp_data
      userProfileData.login_type = login_type;
      await context.$store.dispatch('User/setUserProfileAction', userProfileData)
    }
    
    if (userLoginResp.resp_status) {
      setTimeout(() => {
        /**
         * DeepLink logic 
         */
        let deepLink = context.$store.getters["DeepLink/getDeepLink"]
         if (deepLink) {
          context.$store.commit("DeepLink/deleteDeepLink", null)
          context.$router.push(deepLink)
         }
         else{
           context.$router.push('/home')
         }
      }, 1000)
    }
    else {
      if (userLoginResp.resp_code === 'ERR_USER_VERIFICATION_PENDING') {
        setTimeout(() => {
          context.$router.push('/activate')
        }, 1000)
      }
    else if (userLoginResp.resp_code === 'ERR_USER_PROFILE_COMPLETE_PENDING') {
        setTimeout(() => {
          context.$router.push('/quick-profile')
        }, 1000)
      }
    }
  }

};

export default AutoLogin;
